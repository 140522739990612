let doesHaveCMP;
const purposes = {
  analytics: "analysutv-BcCq4VEq",
  functional: "funktionel-t2meYCeq",
  marketing: "marknadsfo-G6hNqgqm"
};
export function checkConsentForVendor(vendor, callback) {
  if (!hasCMP()) return callback(true);
  window.Exp = window.Exp || {};
  window.Exp.onConsentReady = window.Exp.onConsentReady || [];
  window.Exp.onConsentReady.push(Didomi => {
    return callback(Didomi.getUserConsentStatusForVendor(vendor));
  });
}
export function checkConsentForVendorSync(vendor) {
  if (!hasCMP()) return true;
  if (!window.Didomi) return undefined;
  return window.Didomi.getUserConsentStatusForVendor(vendor);
}
export function checkConsentForPurpose(purpose, callback) {
  if (!hasCMP()) return callback(true);
  window.Exp = window.Exp || {};
  window.Exp.onConsentReady = window.Exp.onConsentReady || [];
  window.Exp.onConsentReady.push(Didomi => {
    return callback(Didomi.getUserConsentStatusForPurpose(purposes[purpose]));
  });
}
export function checkConsentForPurposeSync(purpose) {
  if (!hasCMP()) return true;
  if (!window.Didomi) return undefined;
  return window.Didomi.getUserConsentStatusForPurpose(purposes[purpose]);
}
export function checkConsent(purpose, vendor, callback) {
  if (!hasCMP()) return callback(true);
  window.Exp = window.Exp || {};
  window.Exp.onConsentReady = window.Exp.onConsentReady || [];
  window.Exp.onConsentReady.push(Didomi => {
    return callback(Didomi.getUserConsentStatus(purposes[purpose], vendor));
  });
}
export function checkConsentSync(purpose, vendor) {
  if (!hasCMP()) return true;
  if (!window.Didomi) return undefined;
  return window.Didomi.getUserConsentStatus(purposes[purpose], vendor);
}
export function hasCMP() {
  if (typeof doesHaveCMP === "undefined") {
    doesHaveCMP = !!document.getElementById("cmp-script");
  }
  return doesHaveCMP;
}
export function getPurpose(name, callback) {
  window.Exp = window.Exp || {};
  window.Exp.onConsentReady = window.Exp.onConsentReady || [];
  window.Exp.onConsentReady.push(Didomi => {
    return callback(Didomi.getPurposeById(purposes[name]));
  });
}
export function getPurposeSync(name) {
  if (!window.Didomi) return undefined;
  return window.Didomi.getPurposeById(purposes[name]);
}